import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import apollo from '../assets/001.jpg'
import rinaldoearmida from '../assets/036.jpg'
import davide from '../assets/002.jpg'
import andromeda from '../assets/004.jpg'
import primavera from '../assets/003.jpg'
import autunno from '../assets/005.jpg'

const translations = {
  en: {
    welcome: 'Welcome to',
  },
  fr: {
    welcome: 'Bienvenue à la',
  },
  de: {
    welcome: 'Willkommen in der',
  },
  it: {
    welcome: 'Benvenuto alla',
  },
  es: {
    welcome: 'Bienvenido a la',
  }
};

const artworks = [
  { id: 1, title: 'APOLLO', artist: 'Lutari Giovanni', image: apollo },
  { id: 2, title: 'RINALDO E ARMIDA', artist: 'Tiarini Alessandro', image: rinaldoearmida },
  { id: 3, title: 'DAVIDE CON LA TESTA DI GOLIA', artist: 'Caracciolo Giovanni', image: davide },
  { id: 4, title: 'ANDROMEDA LIBERATA DA PERSEO', artist: 'Manetti Rutilio', image: andromeda },
  { id: 5, title: 'PRIMAVERA', artist: 'Ponte Jacopo', image: primavera },
  { id: 6, title: 'AUTUNNO', artist: 'Ponte Jacopo', image: autunno },
];

const ArtworkCard = ({ title, artist, image }) => (
  <div className="w-full mb-4 relative overflow-hidden rounded-lg shadow-md">
    <img src={image} alt={title} className="w-full h-60 object-cover" />
    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-sm">{artist}</p>
    </div>
  </div>
);

const ListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || 'en';

  const t = translations[lang];

  const handleBackClick = () => {
    navigate(`/galleria-borghese/deaf-user?lang=${lang}`);
  };

  const handleArtworkClick = (id) => {
    navigate(`/galleria-borghese/artwork/${id}?lang=${lang}`);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="sticky top-0 z-10 bg-white">
        <div className="flex items-center justify-between px-4 py-4">
          <button onClick={handleBackClick} className="text-black hover:text-blue-800">
            <ArrowLeft size={24} />
          </button>
          <div className="text-center">
            <h1 className="text-xl">{t.welcome}</h1>
            <h2 className="text-2xl font-bold">GALLERIA BORGHESE</h2>
          </div>
          <div className="w-6" />
        </div>
      </div>
      <div className="flex-grow p-4 overflow-y-auto">
        <div className="grid grid-cols-2 gap-4">
          {artworks.map((artwork) => (
            <div key={artwork.id} onClick={() => handleArtworkClick(artwork.id)}>
              <ArtworkCard {...artwork} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListPage;