import React from 'react';
import { Headphones, FileText, Cuboid, Users } from 'lucide-react';

const WhatWeDoSection = () => {
  const services = [
    {
      icon: <Headphones size={48} />,
      title: 'Audiodescrizioni',
      description: 'Forniamo descrizioni audio dettagliate per aiutare i visitatori non vedenti o ipovedenti a esplorare le opere d\'arte in modo autonomo e coinvolgente.'
    },
    {
      icon: <FileText size={48} />,
      title: 'Trascrizioni',
      description: 'Le nostre trascrizioni consentono ai visitatori sordi o con difficoltà uditive di seguire l\'audioguida o una spiegazione in tempo reale.'
    },
    {
      icon: <Cuboid size={48} />,
      title: 'Modellini 3D',
      description: 'Offriamo modellini tattili delle opere d\'arte per permettere ai visitatori di esplorare sculture e dipinti attraverso il tatto, creando un\'esperienza sensoriale completa.'
    },
    {
      icon: <Users size={48} />,
      title: 'Formazione',
      description: 'Formiamo il personale dei musei affinché possano accogliere e supportare al meglio i visitatori con disabilità, garantendo un ambiente inclusivo e accogliente.'
    }
  ];

  return (
    <div className="bg-transparent py-16 relative overflow-hidden px-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-12">
          Cosa facciamo
        </h2>
        <p className="text-xl text-gray-700 text-center mb-16 max-w-3xl mx-auto">
          Il nostro obiettivo è rendere l'arte accessibile a tutti, offrendo strumenti innovativi per
          migliorare l'esperienza museale di persone con disabilità sensoriali. Attraverso la nostra
          piattaforma, musei e visitatori possono vivere un'esperienza inclusiva e arricchente.
        </p>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {services.map((service, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="flex items-center justify-center h-16 w-16 rounded-full bg-black text-white mb-4">
                {service.icon}
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">{service.title}</h3>
              <p className="text-base text-gray-500 text-center">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoSection;