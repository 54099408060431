import React from 'react';
import { Instagram, Linkedin } from 'lucide-react';
import logo from '../assets/crop.png'

const Footer = () => {
  return (
    <footer className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-8">
          {/* Quick Links - Centered on mobile, first column on desktop */}
          <div className="order-1 md:order-1 flex flex-col items-center md:items-start">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2 text-center md:text-left">
              <li><a href="/" className="text-gray-600 hover:text-gray-900">Home</a></li>
              <li><a href="#features" className="text-gray-600 hover:text-gray-900">Cosa Facciamo</a></li>
              <li><a href="#partenr" className="text-gray-600 hover:text-gray-900">Partners</a></li>
              <li><a href="/contatti" className="text-gray-600 hover:text-gray-900">Contatti</a></li>
            </ul>
            <div className="mt-4 text-center md:text-left">
              <a href="/terms" className="text-sm text-gray-600 hover:text-gray-900">Terms and Conditions</a>
              <span className="mx-2">|</span>
              <a href="/privacy" className="text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
            </div>
          </div>

          {/* Logo and Slogan - Second on mobile, third column on desktop */}
          <div className="order-2 md:order-3 flex flex-col items-center md:items-end">
            <img src={logo} alt="Company Logo" className="h-10 w-auto mb-2" />
            <p className="text-sm text-gray-600 italic text-center md:text-right">L'arte per tutti, senza barriere</p>
          </div>

          {/* Social Icons - Last on mobile, second column on desktop */}
          <div className="order-3 md:order-2 flex flex-col items-center justify-between">
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-gray-900"><Instagram size={24} /></a>
              <a href="#" className="text-gray-400 hover:text-gray-900"><Linkedin size={24} /></a>
            </div>
            <p className="text-sm text-gray-500 mt-4">&copy; 2024 MuSense. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;