import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Terms = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow px-10">
        <h1 className='text-2xl font-bold text-center'>Termini e Condizioni</h1>
        <p>1. Accettazione dei Termini<br></br>
        Utilizzando il sito web e la piattaforma MuSense (di seguito “il Sito”), accetti i presenti Termini e Condizioni. Se non accetti questi termini, non puoi utilizzare il Sito o i servizi offerti.<br></br><br></br>

        2. Descrizione del Servizio<br></br>
        MuSense offre una piattaforma web per fornire accessibilità nei musei, offrendo audiodescrizioni per persone cieche e ipovedenti, trascrizioni in tempo reale per persone sorde e ipoudenti, nonché altre soluzioni di accessibilità tramite l'uso di tag NFC e modellini 3D. L'accesso ai contenuti del museo viene fornito attraverso web app o tramite la scansione dei tag NFC presso i musei partecipanti.<br></br><br></br>

        3. Utilizzo del Sito<br></br>
        L'utente si impegna a utilizzare il Sito solo per scopi legittimi e in conformità con i Termini e Condizioni. Non è consentito:<br></br>

        - Usare il Sito per diffondere contenuti illegali o dannosi.<br></br>
        - Accedere a funzionalità del Sito in modo fraudolento.<br></br>
        - Utilizzare i nostri servizi in violazione delle leggi applicabili.<br></br><br></br>

        4. Proprietà Intellettuale<br></br>
        Tutti i contenuti presenti sul Sito, inclusi, a titolo esemplificativo, testi, immagini, grafici, loghi, suoni e software, sono di proprietà di MuSense o dei suoi partner, e sono protetti dalle leggi sul diritto d'autore. Non è consentito copiare, distribuire, riprodurre o usare i contenuti senza l'autorizzazione scritta di MuSense.<br></br><br></br>

        5. Modifiche ai Servizi<br></br>
        MuSense si riserva il diritto di modificare, sospendere o interrompere qualsiasi parte del Sito o dei servizi offerti senza preavviso. Non saremo responsabili per eventuali modifiche o interruzioni dei servizi.<br></br><br></br>

        6. Esclusione di Responsabilità<br></br>
        I contenuti e i servizi offerti attraverso il Sito sono forniti "così come sono", senza garanzie di alcun tipo. MuSense non garantisce che il Sito sarà privo di errori o interruzioni, né che i contenuti saranno sempre accurati e aggiornati.<br></br><br></br>

        7. Limitazione di Responsabilità<br></br>
        MuSense non sarà responsabile per danni indiretti, incidentali o consequenziali derivanti dall'uso o dall'incapacità di utilizzare il Sito o i servizi offerti, incluso l'uso di tag NFC o la fruizione di contenuti accessibili nel museo.<br></br><br></br>

        8. Modifiche ai Termini<br></br>
        MuSense si riserva il diritto di modificare i presenti Termini e Condizioni in qualsiasi momento. Gli utenti saranno informati delle modifiche e l'uso continuato del Sito dopo tali modifiche costituirà accettazione dei nuovi termini.<br></br><br></br>

        9. Legge Applicabile<br></br>
        I presenti Termini e Condizioni sono regolati dalle leggi italiane. Qualsiasi controversia derivante dall'uso del Sito sarà soggetta alla giurisdizione esclusiva dei tribunali italiani.<br></br><br></br>
        
        Ultimo Aggiornamento: 05/10/2024</p>
      </main>
      <Footer />
    </div>
  );
};

export default Terms;