import React, { useEffect } from 'react';
import { Menu, X } from "lucide-react";
import { useState } from "react";
import logo from "../assets/crop.png";
import { navItems } from "../constants/index";
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const toggleNavbar = () => {
        setMobileDrawerOpen(!mobileDrawerOpen);
    };

    const location = useLocation();
    const isOnHomePage = location.pathname === "/";

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    const handleLogoClick = () => {
        if (isOnHomePage) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <nav className="sticky top-0 z-50 py-2 backdrop-blur-lg border-b border-neutral-300/80">
            <div className="container px-4 mx-auto relative text-sm">
                <div className="flex justify-between items-center">
                    <div className="flex items-center flex-shrink-0">
                        <Link to="/" onClick={handleLogoClick}>
                            <img src={logo} alt="logo" width="180" />
                        </Link>
                    </div>
                    <ul className="hidden lg:flex ml-14 space-x-12">
                        {navItems.map((item, index) => (
                            <li key={index}>
                                <Link to={isOnHomePage ? item.href : `/#${item.href.substring(1)}`}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                    <div className="lg:hidden md:flex flex-col justify-end">
                        <button onClick={toggleNavbar}>
                            {mobileDrawerOpen ? <X /> : <Menu />}
                        </button>
                    </div>
                </div>
                {/* Menu mobile */}
                <div className={`fixed top-0 right-0 z-50 bg-white w-full p-12 flex flex-col justify-center items-center lg:hidden ${mobileDrawerOpen ? 'block' : 'hidden'}`}>
                    <button className="absolute top-0 right-0 m-4" onClick={toggleNavbar}>
                        <X />
                    </button>
                    <ul className="mt-12">
                        {navItems.map((item, index) => (
                            <li key={index} className="py-4">
                                <Link to={isOnHomePage ? item.href : `/#${item.href.substring(1)}`}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;