import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Contacts = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow px-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="relative">
            {/* Gradient circles for background */}
            <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-r from-blue-300 to-purple-300 rounded-full filter blur-3xl opacity-30 -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute bottom-0 right-0 w-96 h-96 bg-gradient-to-l from-pink-300 to-yellow-300 rounded-full filter blur-3xl opacity-30 translate-x-1/2 translate-y-1/2"></div>

            <div className="relative bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-lg shadow-xl p-8">
              <h1 className="text-3xl font-bold text-gray-800 mb-6">Contatti</h1>
              <div className="space-y-4">
                <p className="text-lg text-gray-600">
                  Per qualsiasi domanda scrivici a:{' '}
                  <a href="mailto:info@musenseapp.com" className="text-blue-600 hover:underline">
                    info@musenseapp.com
                  </a>
                </p>
                <p className="text-lg text-gray-600">
                  Per diventare partner contattaci a:{' '}
                  <a href="mailto:partner@musenseapp.com" className="text-blue-600 hover:underline">
                    partner@musenseapp.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contacts;