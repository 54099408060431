import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const languages = [
  { code: 'en', name: 'ENGLISH', flag: '🇬🇧' },
  { code: 'fr', name: 'FRANCAIS', flag: '🇫🇷' },
  { code: 'de', name: 'DEUTSCH', flag: '🇩🇪' },
  { code: 'it', name: 'ITALIANO', flag: '🇮🇹' },
  { code: 'es', name: 'ESPANOL', flag: '🇪🇸' },
];

const WelcomeScreen = () => {
  const navigate = useNavigate();

  const handleLanguageSelect = (langCode) => {
    localStorage.setItem('preferredLanguage', langCode);
    navigate(`/galleria-borghese/disability?lang=${langCode}`);
  };

  return (
    <div className="flex flex-col h-screen bg-white p-4">
      <div className="text-center mb-4 mt-16">
        <h1 className="text-2xl">Welcome to</h1>
        <h2 className="text-3xl font-bold">GALLERIA BORGHESE</h2>
      </div>

      <div className="flex-grow flex flex-col items-center justify-center space-y-4">
        {languages.map((lang) => (
          <button
            key={lang.code}
            className="flex items-center justify-between bg-gray-200 rounded-lg p-4 w-3/4 max-w-md"
            onClick={() => handleLanguageSelect(lang.code)}
          >
            <div className="flex items-center">
              <span className="text-2xl mr-4">{lang.flag}</span>
              <span className="text-lg font-semibold">{lang.name}</span>
            </div>
            <ChevronRight className="text-gray-500" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default WelcomeScreen;