import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/HeroSection';
import Features from './components/Features';
import PartnersSection from './components/Patrners';
import Footer from './components/Footer';


const Home = () => {
  return (
    <>
      <Navbar />
      <div className="relative min-h-screen xl:px-10 sm:px-15">
        {/* Cerchi gradiente */}
        <div className="absolute top-10 left-10 w-64 h-64 bg-gradient-to-r from-blue-300 to-purple-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-40 right-10 w-96 h-96 bg-gradient-to-l from-pink-300 to-yellow-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-1/3 right-1/4 w-80 h-80 bg-gradient-to-t from-green-300 to-cyan-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-3/4 left-1/4 w-72 h-72 bg-gradient-to-br from-indigo-300 to-red-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-[85vh] left-1/3 w-80 h-80 bg-gradient-to-bl from-yellow-300 to-green-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-[95vh] right-1/2 w-64 h-64 bg-gradient-to-r from-orange-300 to-pink-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-[130vh] right-1/4 w-72 h-72 bg-gradient-to-l from-pink-300 to-purple-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-[150vh] left-1/2 w-64 h-64 bg-gradient-to-t from-green-300 to-yellow-300 rounded-full filter blur-3xl opacity-50"></div>
        <div className="absolute top-[160vh] right-1/2 w-72 h-72 bg-gradient-to-tr from-purple-300 to-orange-300 rounded-full filter blur-3xl opacity-50"></div>

        <Hero />
        <div id="features">
          <Features />
        </div>
        <div id="partner">
          <PartnersSection />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
