import React, { useState, useRef, useEffect } from 'react';
import { FileText, Palette, ArrowLeft } from 'lucide-react';
import jsQR from 'jsqr';
import { useNavigate, useLocation } from 'react-router-dom';

const translations = {
  en: {
    welcome: 'Welcome to',
    galleryName: 'GALLERIA BORGHESE',
    activateCamera: 'TAP TO ACTIVATE CAMERA',
    transcribeGuide: 'Transcribe guide in real-time',
    qrNotRecognized: 'QR Code not recognized',
  },
  fr: {
    welcome: 'Bienvenue à la',
    galleryName: 'GALLERIA BORGHESE',
    activateCamera: 'TOUCHEZ POUR ACTIVER LA CAMÉRA',
    transcribeGuide: 'Transcrire le guide en temps réel',
    qrNotRecognized: 'QR Code non reconnu',
  },
  de: {
    welcome: 'Willkommen in der',
    galleryName: 'GALLERIA BORGHESE',
    activateCamera: 'TIPPEN SIE, UM DIE KAMERA ZU AKTIVIEREN',
    transcribeGuide: 'Führung in Echtzeit transkribieren',
    qrNotRecognized: 'QR-Code nicht erkannt',
  },
  it: {
    welcome: 'Benvenuto alla',
    galleryName: 'GALLERIA BORGHESE',
    activateCamera: 'TOCCA PER ATTIVARE LA FOTOCAMERA',
    transcribeGuide: 'Trascrivi guida in tempo reale',
    qrNotRecognized: 'QR Code non riconosciuto',
  },
  es: {
    welcome: 'Bienvenido a la',
    galleryName: 'GALLERIA BORGHESE',
    activateCamera: 'TOQUE PARA ACTIVAR LA CÁMARA',
    transcribeGuide: 'Transcribir guía en tiempo real',
    qrNotRecognized: 'Código QR no reconocido',
  }
};

const DeafUserCameraScreen = () => {
  const [cameraActive, setCameraActive] = useState(false);
  const [qrResult, setQrResult] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || 'en';

  const t = translations[lang];

  // Activate the camera and start QR code detection
  const activateCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      console.log('Stream:', stream); // Controlla che lo stream sia valido
      streamRef.current = stream;
      setCameraActive(true); // Set camera active before accessing videoRef
    } catch (err) {
      console.error("Error accessing the camera:", err);
      alert("Unable to access the camera. Please check your permissions.");
    }
  };

  // Stop the camera when the component is unmounted
  const deactivateCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setCameraActive(false);
  };

  // Function to start detecting QR codes in real-time
  const startQrCodeDetection = () => {
    const detectQrCode = () => {
      if (canvasRef.current && videoRef.current) {
        const context = canvasRef.current.getContext('2d', { willReadFrequently: true });
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
        const qrCode = jsQR(imageData.data, imageData.width, imageData.height);

        if (qrCode) {
          setQrResult(qrCode.data);
          console.log(`QR Code detected: ${qrCode.data}`);

          // Disattiva la fotocamera e naviga in base ai dati del QR code
          deactivateCamera();

          // Navigate to a specific route based on the QR code data
          if (qrCode.data.includes('https://www.musenseapp.com/galleria-borghese/artwork/')) {
            // Estrai l'ID dell'opera d'arte dall'URL
            const artworkId = qrCode.data.split('/').pop();
            // Naviga alla pagina corrispondente all'ID dell'opera
            navigate(`/galleria-borghese/artwork/${artworkId}/?lang=${lang}`);
        } else if (qrCode.data === 'transcription') {
            navigate('/galleria-borghese/transcription');
        } else {
            alert('QR Code not detected');
        }        
        }
      }
      requestAnimationFrame(detectQrCode);
    };

    requestAnimationFrame(detectQrCode);
  };

  useEffect(() => {
    if (cameraActive && videoRef.current) {
      videoRef.current.srcObject = streamRef.current;
      videoRef.current.onloadedmetadata = () => {
        console.log('Metadata loaded, playing video...');
        videoRef.current.play().catch(error => {
          console.error('Error trying to play the video:', error);
        });
      };
      startQrCodeDetection();
    }
  }, [cameraActive]);

  const handleNavigate2 = () => {
    navigate(`/galleria-borghese/transcription?lang=${lang}`);
  };

  const handleNavigate = () => {
    navigate(`/galleria-borghese/list?lang=${lang}`);
  };

  const handleBackClick = () => {
    navigate(`/galleria-borghese/disability?lang=${lang}`);
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="sticky top-0 z-10 bg-white">
        <div className="flex items-center justify-between px-4 py-4">
          <button onClick={handleBackClick} className="text-black hover:text-blue-800">
            <ArrowLeft size={24} />
          </button>
          <div className="text-center">
            <h1 className="text-xl">{t.welcome}</h1>
            <h2 className="text-2xl font-bold">GALLERIA BORGHESE</h2>
          </div>
          <div className="w-6" />
        </div>
      </div>
      
      <div className="flex-grow flex flex-col justify-center items-center px-4">
        {cameraActive ? (
          <div className="w-full h-3/4 bg-black flex items-center justify-center">
            <video 
              ref={videoRef} 
              autoPlay 
              playsInline 
              className="w-full h-full object-cover"
              style={{ display: 'block' }}
            />
            <canvas ref={canvasRef} className="hidden" width="640" height="480"></canvas>
          </div>
        ) : (
          <div 
            className="w-full h-3/4 bg-gray-200 flex items-center justify-center cursor-pointer"
            onClick={activateCamera}
          >
            <p className="text-xl font-bold items-center justify-center">{t.activateCamera}</p>
          </div>
        )}
      </div>

      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 flex items-center space-x-4">
        <button 
          className="bg-gray-200 rounded-lg py-3 px-4 flex-grow flex items-center space-x-4"
          onClick={handleNavigate2}
        >
          <FileText size={24} />
          <span className="flex-grow">{t.transcribeGuide}</span>
        </button>

        <button 
          className="bg-black text-white rounded-full w-12 h-12 flex items-center justify-center"
          onClick={handleNavigate}
        >
          <Palette size={24} />
        </button>
      </div>
    </div>
  );
};

export default DeafUserCameraScreen;