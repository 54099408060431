import { ArrowLeft } from 'lucide-react';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const translations = {
  en: {
    welcome: 'Welcome to',
    galleryName: 'GALLERIA BORGHESE',
    start: 'Start',
    stop: 'Stop',
    unsupportedBrowser: "This browser doesn't support speech recognition.",
  },
  fr: {
    welcome: 'Bienvenue à',
    galleryName: 'GALLERIA BORGHESE',
    start: 'Start',
    stop: 'Stop',
    unsupportedBrowser: "Ce navigateur ne prend pas en charge la reconnaissance vocale.",
  },
  de: {
    welcome: 'Willkommen in der',
    galleryName: 'GALLERIA BORGHESE',
    start: 'Start',
    stop: 'Stop',
    unsupportedBrowser: "Dieser Browser unterstützt keine Spracherkennung.",
  },
  it: {
    welcome: 'Benvenuto a',
    galleryName: 'GALLERIA BORGHESE',
    start: 'Start',
    stop: 'Stop',
    unsupportedBrowser: "Questo browser non supporta il riconoscimento vocale.",
  },
  es: {
    welcome: 'Bienvenido a',
    galleryName: 'GALLERIA BORGHESE',
    start: 'Start',
    stop: 'Stop',
    unsupportedBrowser: "Este navegador no admite el reconocimiento de voz.",
  }
};

const languageToLocale = {
  en: 'en-US',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  es: 'es-ES'
};

const TranscriptionPage = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || 'en';

  const t = translations[lang];

  useEffect(() => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;
      recognitionInstance.lang = languageToLocale[lang];
      recognitionInstance.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        setTranscript(finalTranscript + interimTranscript);
      };
      setRecognition(recognitionInstance);
    } else {
      console.error(t.unsupportedBrowser);
    }
  }, [lang, t]);

  const startRecording = useCallback(() => {
    if (recognition) {
      recognition.start();
      setIsRecording(true);
    }
  }, [recognition]);

  const stopRecording = useCallback(() => {
    if (recognition) {
      recognition.stop();
      setIsRecording(false);
    }
  }, [recognition]);

  const handleRecordingToggle = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleBackClick = () => {
    navigate(`/galleria-borghese/deaf-user?lang=${lang}`);
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="sticky top-0 z-10 bg-white">
        <div className="flex items-center justify-between px-4 py-4">
          <button onClick={handleBackClick} className="text-black hover:text-blue-800">
            <ArrowLeft size={24} />
          </button>
          <div className="text-center">
            <h1 className="text-xl">{t.welcome}</h1>
            <h2 className="text-2xl font-bold">{t.galleryName}</h2>
          </div>
          <div className="w-6" />
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4">
          <p className="text-xl">{transcript}</p>
        </div>
      </div>
      <div className="sticky bottom-0 p-4 flex justify-center bg-white">
        <button
          className={`bg-black text-white rounded-full w-16 h-16 flex items-center justify-center ${
            isRecording ? 'bg-red-500' : ''
          }`}
          onClick={handleRecordingToggle}
        >
          {isRecording ? t.stop : t.start}
        </button>
      </div>
    </div>
  );
};

export default TranscriptionPage;

