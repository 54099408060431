import React from 'react';
import logo from '../assets/cropped-removebg-preview.png';
import phoneImage from '../assets/001.png';

const HeroSection = () => {
  return (
    <section className="relative flex flex-col lg:flex-row items-center min-h-screen overflow-hidden bg-trasparent px-10 sm:px-10 lg:px-40">
      <div className="container mx-auto z-10">
        <div className="flex flex-col items-center lg:flex-row justify-between">
          <div className="lg:hidden flex justify-center">
            <img src={logo} alt="Logo" className="w-24 sm:w-32 lg:w-40 mb-4 pt-10" />
          </div>

          <div className="w-full lg:w-1/2 mb-8 lg:mb-0 text-center lg:text-left pt-10">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold leading-tight mb-4">
              Rendiamo i{" "}
              <span className="text-black">Musei</span>{" "}
              Accessibili a Tutti
            </h1>
            <p className="text-lg mb-6 text-gray-700 max-w-xl mx-auto lg:mx-0">
              Scopri un'esperienza museale inclusiva con audiodescrizioni, trascrizioni in tempo reale e modellini 3D, pensata per persone con disabilità sensoriali.
            </p>
            <a href='#features' className="bg-black text-white px-6 py-3 rounded-full font-semibold hover:bg-gray-800 transition duration-300">
              Scopri di più
            </a>
          </div>

          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end relative">
            <img src={phoneImage} alt="App Preview" className="max-w-full h-auto sm:max-w-xs md:max-w-sm lg:max-w-md relative z-10" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;


