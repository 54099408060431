import React from 'react';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import Contact from './pages/LandingPage/components/Contacts'
import Terms from './pages/LandingPage/components/Terms'
import Privacy from './pages/LandingPage/components/Privacy';
import LanguagePage from './pages/GalleriaBorghese/components/LanguagePage';
import DisabilityPage from './pages/GalleriaBorghese/components/DisabilityPage';
import CameraPage from './pages/GalleriaBorghese/components/CameraPage'
import TranscriptionPage from './pages/GalleriaBorghese/components/TranscriptionPage';
import ListPage from './pages/GalleriaBorghese/components/ListPage';
import ArtworkPage from './pages/GalleriaBorghese/components/ArtworkPage';
import AudioPage from './pages/GalleriaBorghese/components/AudioPage'
import DescriptionPage from './pages/GalleriaBorghese/components/DescriptionPage'


const App = () => {
  return (
    <>
      <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/contatti" element={<Contact />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/galleria-borghese" element={<LanguagePage />} />
              <Route path="/galleria-borghese/disability" element={<DisabilityPage />} />
              <Route path="/galleria-borghese/deaf-user" element={<CameraPage />} />
              <Route path="/galleria-borghese/blind-user" element={<AudioPage />} />
              <Route path="/galleria-borghese/transcription" element={<TranscriptionPage />} />
              <Route path="/galleria-borghese/list" element={<ListPage />} />
              <Route path='/galleria-borghese/artwork/:id' element={<ArtworkPage />} />
              <Route path='/galleria-borghese/artwork-description/:artworkId' element={<DescriptionPage />} />
            </Routes>
      </Router>
    </>
  )
}

export default App;
