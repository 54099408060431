import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Privacy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow px-10">
        <h1 className='text-2xl font-bold text-center'>Privacy Policy</h1>
        <p>1. Tipologia di Dati Raccolti<br></br>
        MuSense raccoglie i seguenti dati personali:<br></br>

        - Dati di navigazione: Informazioni raccolte automaticamente durante la navigazione sul Sito (es. indirizzo IP, informazioni sul dispositivo, cookies).<br></br>
        - Dati forniti dall'utente: Dati inseriti dall'utente (es. nome, indirizzo email) per l'uso dei servizi o per comunicazioni.<br></br><br></br>
        2. Finalità del Trattamento<br></br>
        I dati personali raccolti sono trattati per le seguenti finalità:<br></br>

        - Fornire e migliorare i servizi di accessibilità museale.<br></br>
        - Gestire le iscrizioni e l'accesso ai contenuti digitali.<br></br>
        - Garantire la sicurezza del Sito e prevenire frodi.<br></br>
        - Rispondere a richieste o comunicazioni dell'utente.<br></br><br></br>
        3. Base Giuridica del Trattamento<br></br>
        Il trattamento dei dati personali è legittimato dal consenso dell’utente, dall’esecuzione di un contratto o dall’obbligo legale, a seconda del contesto in cui i dati vengono trattati.<br></br><br></br>

        4. Modalità di Trattamento dei Dati<br></br>
        I dati personali saranno trattati con strumenti elettronici e manuali, in modo da garantire la sicurezza e la riservatezza dei dati stessi.<br></br><br></br>

        5. Conservazione dei Dati<br></br>
        I dati personali saranno conservati per il tempo strettamente necessario a soddisfare le finalità per cui sono stati raccolti, o come richiesto dalla legge.<br></br><br></br>

        6. Condivisione dei Dati<br></br>
        MuSense non condividerà i dati personali degli utenti con terze parti, a meno che non sia necessario per fornire i servizi o per ottemperare a obblighi di legge. I dati potranno essere condivisi con:<br></br>

        - Fornitori di servizi esterni che gestiscono le funzionalità del Sito (es. servizi di hosting).<br></br>
        - Autorità competenti, nel caso sia richiesto dalla legge.<br></br><br></br>
        7. Diritti dell'Utente<br></br>
        Gli utenti hanno il diritto di:<br></br>

        - Accedere ai propri dati personali.<br></br>
        - Richiedere la rettifica o la cancellazione dei dati.<br></br>
        - Opporsi al trattamento o richiederne la limitazione.<br></br>
        - Richiedere la portabilità dei dati.<br></br>
        Per esercitare questi diritti, gli utenti possono contattarci all'indirizzo email info@musenseapp.com.<br></br><br></br>

        8. Cookie<br></br>
        Il Sito utilizza cookie per migliorare l'esperienza di navigazione. Gli utenti possono gestire le preferenze relative ai cookie attraverso le impostazioni del proprio browser.<br></br><br></br>

        9. Modifiche alla Privacy Policy<br></br>
        MuSense si riserva il diritto di modificare la presente Privacy Policy. Gli utenti saranno informati di eventuali modifiche tramite avvisi sul Sito.<br></br><br></br>

        10. Contatti<br></br>
        Per qualsiasi domanda o richiesta relativa alla privacy, gli utenti possono contattarci all'indirizzo email: info@musenseapp.com.<br></br><br></br>
        
        Ultimo Aggiornamento: 05/10/2024</p>
      </main>
      <Footer />
    </div>
  );
};

export default Privacy;