import React from 'react';
import { Users } from 'lucide-react';
import novesetteuno from '../assets/971-removebg-preview.png'
import bk from '../assets/BK-2.png'

const PartnersSection = () => {
  // Placeholder data for partner boxes
  const partnerPlaceholders = [
    { id: 1, name: 'Partner 1' },
    { id: 2, name: 'Partner 2' },
    { id: 3, name: 'Partner 3' },
    { id: 4, name: 'Partner 4' },
  ];

  // Placeholder data for supporting companies
  const supportingCompanies = [
    { id: 1, name: '971 AM S.r.l.', logo: novesetteuno },
    { id: 2, name: 'BK S.r.l.', logo: bk, url:'https://benignibk.com/'},
  ];

  return (
    <section id="partners" className="bg-transparent py-16 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
      <div className="max-w-7xl mx-auto relative z-10">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 text-center mb-8">
          I Nostri Partner
        </h2>
        <p className="text-lg sm:text-xl text-gray-700 text-center mb-8 max-w-3xl mx-auto">
          Siamo attualmente alla ricerca di partner per collaborare nel rendere l'arte accessibile a tutti.
          Se sei un museo interessato a diventare nostro partner, non esitare a contattarci!
        </p>
        <div className="flex justify-center mb-12">
          <a
            href="/contatti"
            className="bg-black text-white px-6 py-3 rounded-full font-semibold hover:bg-gray-800 transition duration-300"
          >
            Diventa un Partner
          </a>
        </div>
        <div className="mb-12">
          <h3 className="text-xl sm:text-2xl font-bold text-gray-900 text-center mb-6">
            Come appariranno i nostri partner
          </h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6">
            {partnerPlaceholders.map((partner) => (
              <div
                key={partner.id}
                className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col items-center justify-center h-32 sm:h-40"
              >
                <Users size={36} className="text-gray-400 mb-2" />
                <p className="text-sm sm:text-base text-gray-600 text-center">{partner.name}</p>
              </div>
            ))}
          </div>
        </div>
        <p className="text-sm sm:text-base text-gray-600 text-center italic mb-12">
          Questi sono esempi di come i loghi e i nomi dei partner verranno visualizzati sulla pagina.
        </p>
        
        <div className="mb-12">
          <h3 className="text-xl sm:text-2xl font-bold text-gray-900 text-center mb-6">
            Supportati da
          </h3>
          <div className="flex flex-wrap justify-center items-center gap-10">
            {supportingCompanies.map((company) => (
              <div key={company.id} className="flex flex-col items-center">
                <a href={company.url} target="_blank" rel="noopener noreferrer" className="hover:opacity-80 transition-opacity">
                  <img
                    src={company.logo}
                    alt={`Logo di ${company.name}`}
                    className="h-32 sm:h-40 md:h-48 w-auto object-contain"
                  />
                </a>
                <p className="text-sm text-gray-600 mt-2">{company.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;