import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, Pause, Play } from 'lucide-react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// Import audio files for different artworks in different languages
import apolloAudioIt from '../assets/audio/italiano/opere/apollo.mp3';
import apolloAudioEn from '../assets/audio/italiano/opere/apollo.mp3';
import apolloAudioFr from '../assets/audio/italiano/opere/apollo.mp3';
import apolloAudioDe from '../assets/audio/italiano/opere/apollo.mp3';
import apolloAudioEs from '../assets/audio/italiano/opere/apollo.mp3';

const languages = {
    it: {
      welcome: "Benvenuto alla",
      audioPlaying: "AUDIO IN RIPRODUZIONE",
      audioEnded: "AUDIO TERMINATO",
      play: "RIPRODUCI",
      pause: "PAUSA"
    },
    en: {
      welcome: "Welcome to",
      audioPlaying: "AUDIO PLAYING",
      audioEnded: "AUDIO ENDED",
      play: "PLAY",
      pause: "PAUSE"
    },
    fr: {
      welcome: "Bienvenue à la",
      audioPlaying: "AUDIO EN LECTURE",
      audioEnded: "AUDIO TERMINÉ",
      play: "JOUER",
      pause: "PAUSE"
    },
    de: {
      welcome: "Willkommen in der",
      audioPlaying: "AUDIO WIRD ABGESPIELT",
      audioEnded: "AUDIO BEENDET",
      play: "SPIELEN",
      pause: "PAUSE"
    },
    es: {
      welcome: "Bienvenido a la",
      audioPlaying: "AUDIO REPRODUCIÉNDOSE",
      audioEnded: "AUDIO TERMINADO",
      play: "REPRODUCIR",
      pause: "PAUSA"
    }
  };   

const artworks = {
  apollo: {
    title: 'APOLLO',
    artist: 'Luteri Giovanni',
    audio: {
      it: apolloAudioIt,
      en: apolloAudioEn,
      fr: apolloAudioFr,
      de: apolloAudioDe,
      es: apolloAudioEs
    }
  },
};

const ArtworkAudioDescription = () => {
    const navigate = useNavigate();
    const { artworkId } = useParams();
    const location = useLocation();
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioEnded, setAudioEnded] = useState(false);
    const audioRef = useRef(null);
  
    // Funzione per ottenere la lingua
    const getLanguage = () => {
      const queryParams = new URLSearchParams(location.search);
      const urlLang = queryParams.get('lang');
      
      if (urlLang && languages[urlLang]) {
        localStorage.setItem('preferredLanguage', urlLang);
        return urlLang;
      }
  
      const storedLang = localStorage.getItem('preferredLanguage');
      if (storedLang && languages[storedLang]) {
        return storedLang;
      }
  
      return 'en'; // Lingua di default
    };
  
    const [lang, setLang] = useState(getLanguage());
  
    useEffect(() => {
      setLang(getLanguage());
    }, [location.search]);
  
    useEffect(() => {
      if (artworkId && artworks[artworkId]) {
        audioRef.current = new Audio(artworks[artworkId].audio[lang]);
        audioRef.current.addEventListener('ended', handleAudioEnd);
      }
  
      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.removeEventListener('ended', handleAudioEnd);
        }
      };
    }, [artworkId, lang]);
  
    const handleAudioEnd = () => {
      setIsPlaying(false);
      setAudioEnded(true);
    };
  
    const toggleAudio = () => {
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play().catch(error => {
            console.error("Error playing audio:", error);
          });
          setAudioEnded(false);
        }
        setIsPlaying(!isPlaying);
      }
    };
  
    const handleBackClick = () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      navigate(-1);
    };
  
    if (!artworkId || !artworks[artworkId]) {
      return <div>Artwork not found</div>;
    }
  
    const { title, artist } = artworks[artworkId];
  
    return (
      <div className="flex flex-col h-screen bg-white">
        <div className="sticky top-0 z-10 bg-white">
          <div className="flex items-center justify-between px-4 py-4">
            <button 
              onClick={handleBackClick} 
              className="text-black hover:text-blue-800"
              aria-label="Back"
            >
              <ArrowLeft size={24} />
            </button>
            <div className="text-center">
              <h1 className="text-xl">{languages[lang].welcome}</h1>
              <h2 className="text-2xl font-bold">GALLERIA BORGHESE</h2>
            </div>
            <div className="w-6" />
          </div>
        </div>
        <div className="flex-grow flex flex-col items-center justify-center p-4">
          <h3 className="text-3xl font-bold mb-2">{title}</h3>
          <p className="text-xl mb-8">{artist}</p>
          <button
            onClick={toggleAudio}
            className="w-64 h-64 rounded-full bg-black text-white text-2xl font-bold shadow-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 flex items-center justify-center"
            aria-label={isPlaying ? languages[lang].pause : languages[lang].play}
          >
            {isPlaying ? (
              <Pause size={48} />
            ) : (
              <Play size={48} />
            )}
          </button>
          <p className="text-2xl font-bold mt-8">
            {audioEnded ? languages[lang].audioEnded : 
             isPlaying ? languages[lang].audioPlaying : 
             languages[lang].play}
          </p>
        </div>
      </div>
    );
  };
  
  export default ArtworkAudioDescription;