import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EarOff, EyeOff } from 'lucide-react';

const translations = {
  en: {
    welcome: 'Welcome to',
    galleryName: 'GALLERIA BORGHESE',
    deafness: 'DEAFNESS',
    blindness: 'BLINDNESS'
  },
  fr: {
    welcome: 'Bienvenue à',
    galleryName: 'GALLERIA BORGHESE',
    deafness: 'SURDITÉ',
    blindness: 'CÉCITÉ'
  },
  de: {
    welcome: 'Willkommen in der',
    galleryName: 'GALLERIA BORGHESE',
    deafness: 'TAUBHEIT',
    blindness: 'BLINDHEIT'
  },
  it: {
    welcome: 'Benvenuto alla',
    galleryName: 'GALLERIA BORGHESE',
    deafness: 'SORDITÀ',
    blindness: 'CECITÀ'
  },
  es: {
    welcome: 'Bienvenido a la',
    galleryName: 'GALLERIA BORGHESE',
    deafness: 'SORDERA',
    blindness: 'CEGUERA'
  }
};

const DisabilitySelectionScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || 'en';

  const handleDisabilitySelection = (disability) => {
    if (disability === 'deafness') {
      navigate(`/galleria-borghese/deaf-user?lang=${lang}`);
    } else if (disability === 'blindness') {
      navigate(`/galleria-borghese/blind-user?lang=${lang}`);
    }
  };

  const t = translations[lang];

  return (
    <div className="flex flex-col h-screen bg-white p-4">
      <div className="text-center mb-4 mt-16">
        <h1 className="text-2xl">{t.welcome}</h1>
        <h2 className="text-3xl font-bold">{t.galleryName}</h2>
      </div>
      <div className="flex-grow flex flex-col items-center justify-center space-y-8">
        <button
          onClick={() => handleDisabilitySelection('deafness')}
          className="flex flex-col items-center"
        >
          <div className="bg-gray-200 p-8 rounded-lg mb-2">
            <EarOff size={60} />
          </div>
          <span className="text-lg font-semibold">{t.deafness}</span>
        </button>
        <button
          onClick={() => handleDisabilitySelection('blindness')}
          className="flex flex-col items-center"
        >
          <div className="bg-gray-200 p-8 rounded-lg mb-2">
            <EyeOff size={60} />
          </div>
          <span className="text-lg font-semibold">{t.blindness}</span>
        </button>
      </div>
    </div>
  );
};

export default DisabilitySelectionScreen;