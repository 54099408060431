import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import apollo from '../assets/001.jpg'
import rinaldoearmida from '../assets/036.jpg'
import davide from '../assets/002.jpg'
import andromeda from '../assets/004.jpg'
import primavera from '../assets/003.jpg'
import autunno from '../assets/005.jpg'

const artworksData = {
  1: {
    id: 1,
    title: 'APOLLO',
    artist: 'Lutari Giovanni',
    image: apollo,
    descriptions: {
      en: 'Apollo, god of music and poetry, is depicted here in his youthful beauty. The sculpture captures the grace and power associated with this important figure in classical mythology.',
      fr: 'Apollon, dieu de la musique et de la poésie, est représenté ici dans sa beauté juvénile. La sculpture capture la grâce et la puissance associées à cette figure importante de la mythologie classique.',
      de: 'Apollo, der Gott der Musik und Dichtung, wird hier in seiner jugendlichen Schönheit dargestellt. Die Skulptur fängt die Anmut und Kraft ein, die mit dieser wichtigen Figur der klassischen Mythologie verbunden sind.',
      it: 'Apollo, dio della musica e della poesia, è raffigurato qui nella sua bellezza giovanile. La scultura cattura la grazia e la potenza associate a questa importante figura della mitologia classica.',
      es: 'Apolo, dios de la música y la poesía, se representa aquí en su belleza juvenil. La escultura captura la gracia y el poder asociados con esta importante figura de la mitología clásica.'
    }
  },
  2: {
    id: 2,
    title: 'RINALDO E ARMIDA',
    artist: 'Tiarini Alessandro',
    image: rinaldoearmida,
    descriptions: {
      en: 'This painting depicts a scene from Tasso\'s "Jerusalem Delivered", showing Rinaldo and Armida. It captures a moment of tension between love and duty, a common theme in Renaissance art.',
      fr: 'Ce tableau représente une scène de la "Jérusalem délivrée" du Tasse, montrant Renaud et Armide. Il capture un moment de tension entre l\'amour et le devoir, un thème commun dans l\'art de la Renaissance.',
      de: 'Dieses Gemälde stellt eine Szene aus Tassos "Befreites Jerusalem" dar und zeigt Rinaldo und Armida. Es fängt einen Moment der Spannung zwischen Liebe und Pflicht ein, ein häufiges Thema in der Kunst der Renaissance.',
      it: 'Questo dipinto raffigura una scena dalla "Gerusalemme liberata" di Tasso, mostrando Rinaldo e Armida. Cattura un momento di tensione tra amore e dovere, un tema comune nell\'arte rinascimentale.',
      es: 'Esta pintura representa una escena de "Jerusalén liberada" de Tasso, mostrando a Rinaldo y Armida. Captura un momento de tensión entre el amor y el deber, un tema común en el arte renacentista.'
    }
  },
  3: {
    id: 3,
    title: 'DAVIDE CON LA TESTA DI GOLIA',
    artist: 'Caracciolo Giovanni',
    image: davide,
    descriptions: {
      en: 'This powerful painting shows David with the head of Goliath, a scene from the biblical story. It highlights the triumph of the underdog and the power of faith.',
      fr: 'Ce tableau puissant montre David avec la tête de Goliath, une scène de l\'histoire biblique. Il met en évidence le triomphe du plus faible et la puissance de la foi.',
      de: 'Dieses kraftvolle Gemälde zeigt David mit dem Kopf von Goliath, eine Szene aus der biblischen Geschichte. Es unterstreicht den Triumph des Underdogs und die Macht des Glaubens.',
      it: 'Questo potente dipinto mostra Davide con la testa di Golia, una scena della storia biblica. Mette in evidenza il trionfo del più debole e la potenza della fede.',
      es: 'Esta poderosa pintura muestra a David con la cabeza de Goliat, una escena de la historia bíblica. Destaca el triunfo del desvalido y el poder de la fe.'
    }
  },
  4: {
    id: 4,
    title: 'ANDROMEDA LIBERATA DA TESEO',
    artist: 'Manetti Rutilio',
    image: andromeda,
    descriptions: {
      en: 'This painting portrays the mythological scene of Andromeda being freed by Perseus. It showcases the heroic rescue and the defeat of the sea monster.',
      fr: 'Ce tableau représente la scène mythologique d\'Andromède libérée par Persée. Il met en scène le sauvetage héroïque et la défaite du monstre marin.',
      de: 'Dieses Gemälde stellt die mythologische Szene dar, in der Andromeda von Perseus befreit wird. Es zeigt die heroische Rettung und die Niederlage des Seemonsters.',
      it: 'Questo dipinto raffigura la scena mitologica di Andromeda liberata da Perseo. Mette in mostra il salvataggio eroico e la sconfitta del mostro marino.',
      es: 'Esta pintura representa la escena mitológica de Andrómeda siendo liberada por Perseo. Muestra el rescate heroico y la derrota del monstruo marino.'
    }
  },
  5: {
    id: 5,
    title: 'PRIMAVERA',
    artist: 'Ponte Jacopo',
    image: primavera,
    descriptions: {
      en: 'This allegorical painting represents Spring. It\'s part of a series depicting the four seasons, showcasing the artist\'s skill in capturing the essence of each time of year.',
      fr: 'Cette peinture allégorique représente le Printemps. Elle fait partie d\'une série représentant les quatre saisons, démontrant l\'habileté de l\'artiste à capturer l\'essence de chaque période de l\'année.',
      de: 'Dieses allegorische Gemälde stellt den Frühling dar. Es ist Teil einer Serie, die die vier Jahreszeiten darstellt und die Fähigkeit des Künstlers zeigt, das Wesen jeder Jahreszeit einzufangen.',
      it: 'Questo dipinto allegorico rappresenta la Primavera. Fa parte di una serie che raffigura le quattro stagioni, mostrando l\'abilità dell\'artista nel catturare l\'essenza di ogni periodo dell\'anno.',
      es: 'Esta pintura alegórica representa la Primavera. Es parte de una serie que representa las cuatro estaciones, mostrando la habilidad del artista para capturar la esencia de cada época del año.'
    }
  },
  6: {
    id: 6,
    title: 'AUTUNNO',
    artist: 'Ponte Jacopo',
    image: autunno,
    descriptions: {
      en: 'This painting depicts Autumn, part of a series on the four seasons. It showcases the rich colors and bountiful harvest associated with this time of year.',
      fr: 'Ce tableau représente l\'Automne, faisant partie d\'une série sur les quatre saisons. Il met en valeur les riches couleurs et la récolte abondante associées à cette période de l\'année.',
      de: 'Dieses Gemälde stellt den Herbst dar und ist Teil einer Serie über die vier Jahreszeiten. Es zeigt die reichen Farben und die reiche Ernte, die mit dieser Jahreszeit verbunden sind.',
      it: 'Questo dipinto raffigura l\'Autunno, parte di una serie sulle quattro stagioni. Mette in mostra i ricchi colori e il raccolto abbondante associati a questo periodo dell\'anno.',
      es: 'Esta pintura representa el Otoño, parte de una serie sobre las cuatro estaciones. Muestra los ricos colores y la abundante cosecha asociados con esta época del año.'
    }
  }
};

const translations = {
  en: {
    welcome: "Welcome to",
    loading: "Loading...",
  },
  fr: {
    welcome: "Bienvenue à la",
    loading: "Chargement...",
  },
  de: {
    welcome: "Willkommen in der",
    loading: "Laden...",
  },
  it: {
    welcome: "Benvenuto alla",
    loading: "Caricamento...",
  },
  es: {
    welcome: "Bienvenido a la",
    loading: "Cargando...",
  }
};

const ArtworkDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [artwork, setArtwork] = useState(null);
  const [lang, setLang] = useState('en');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langParam = params.get('lang');
    if (langParam && translations[langParam]) {
      setLang(langParam);
    }

    const fetchedArtwork = artworksData[id];
    if (fetchedArtwork) {
      setArtwork(fetchedArtwork);
    } else {
      console.error('Artwork not found');
    }
  }, [id, location.search]);

  const handleBackClick = () => {
    navigate(-1);
  };

  if (!artwork) {
    return <div>{translations[lang].loading}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="sticky top-0 z-10 bg-white">
        <div className="flex items-center justify-between px-4 py-4">
          <button onClick={handleBackClick} className="text-black hover:text-blue-800">
            <ArrowLeft size={24} />
          </button>
          <div className="text-center">
            <h1 className="text-xl">{translations[lang].welcome}</h1>
            <h2 className="text-2xl font-bold">GALLERIA BORGHESE</h2>
          </div>
          <div className="w-6" />
        </div>
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        <img src={artwork.image} alt={artwork.title} className="w-full h-80 object-cover rounded-lg mb-4" />
        <h1 className="text-2xl font-bold mb-2">{artwork.title}</h1>
        <h2 className="text-xl mb-4">{artwork.artist}</h2>
        <p className="text-gray-700">{artwork.descriptions[lang]}</p>
      </div>
    </div>
  );
};

export default ArtworkDetailPage;