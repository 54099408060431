import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

// Import all audio files
import introEnglish from '../assets/audio/inglese/intro.mp3';
import introFrench from '../assets/audio/francese/intro.mp3';
import introGerman from '../assets/audio/tedesco/intro.mp3';
import introItalian from '../assets/audio/italiano/intro.mp3';
import introSpanish from '../assets/audio/spagnolo/intro.mp3';

import backEnglish from '../assets/audio/inglese/backEnglish.mp3';
import step2English from '../assets/audio/inglese/step2English.mp3';
import step3English from '../assets/audio/inglese/step3English.mp3';
import helpEnglish from '../assets/audio/inglese/helpEnglish.mp3';
import commandEnglish from '../assets/audio/inglese/commandEnglish.mp3';
import finishEnglish from '../assets/audio/inglese/finishEnglish.mp3';

import backFrench from '../assets/audio/francese/back.mp3';
import step2French from '../assets/audio/francese/step2.mp3';
import step3French from '../assets/audio/francese/step3.mp3';
import helpFrench from '../assets/audio/francese/help.mp3';
import commandFrench from '../assets/audio/francese/command.mp3';
import finishFrench from '../assets/audio/francese/finish.mp3';

import backGerman from '../assets/audio/tedesco/back.mp3';
import step2German from '../assets/audio/tedesco/step2.mp3';
import step3German from '../assets/audio/tedesco/step3.mp3';
import helpGerman from '../assets/audio/tedesco/help.mp3';
import commandGerman from '../assets/audio/tedesco/command.mp3';
import finishGerman from '../assets/audio/tedesco/finish.mp3';

import backItalian from '../assets/audio/italiano/back.mp3';
import step2Italian from '../assets/audio/italiano/step2.mp3';
import step3Italian from '../assets/audio/italiano/step3.mp3';
import helpItalian from '../assets/audio/italiano/help.mp3';
import commandItalian from '../assets/audio/italiano/command.mp3';
import finishItalian from '../assets/audio/italiano/finish.mp3';

import backSpanish from '../assets/audio/spagnolo/back.mp3';
import step2Spanish from '../assets/audio/spagnolo/step2.mp3';
import step3Spanish from '../assets/audio/spagnolo/step3.mp3';
import helpSpanish from '../assets/audio/spagnolo/help.mp3';
import commandSpanish from '../assets/audio/spagnolo/command.mp3';
import finishSpanish from '../assets/audio/spagnolo/finish.mp3';

const languages = {
  en: {
    code: 'en-US',
    name: 'English',
    intro: introEnglish,
    tourSteps: [
      { id: 1, description: backEnglish },
      { id: 2, description: step2English },
      { id: 3, description: step3English },
    ],
    uiText: {
      title: "Welcome to",
      backButton: "Back",
      introPlaying: "Playing introduction...",
      activateAssistant: "Tap to activate voice assistant",
      listening: "Listening...",
      tourStep: "Tour step",
      of: "of",
      commands: {
        next: "next",
        back: "back",
        repeat: "repeat",
        help: "help"
      },
      helpMessage: helpEnglish,
      commandNotRecognized: commandEnglish,
      tourCompleted: finishEnglish,
      cannotGoBack: backEnglish,
    }
  },
  fr: {
    code: 'fr-FR',
    name: 'Français',
    intro: introFrench,
    tourSteps: [
      { id: 1, description: backFrench },
      { id: 2, description: step2French },
      { id: 3, description: step3French },
    ],
    uiText: {
      title: "Bienvenue à la",
      backButton: "précédent",
      introPlaying: "Lecture de l'introduction...",
      activateAssistant: "Appuyez pour activer l'assistant vocal",
      listening: "Écoute...",
      tourStep: "Étape de la visite",
      of: "sur",
      commands: {
        next: "suivant",
        back: "précédent",
        repeat: "répéter",
        help: "aide"
      },
      helpMessage: helpFrench,
      commandNotRecognized: commandFrench,
      tourCompleted: finishFrench,
      cannotGoBack: backFrench,
    }
  },
  de: {
    code: 'de-DE',
    name: 'Deutsch',
    intro: introGerman,
    tourSteps: [
      {
        id: 1,
        description: backGerman,
      },
      {
        id: 2,
        description: step2German,
      },
      {
        id: 3,
        description: step3German,
      },
    ],
    uiText: {
      title: "Willkommen in der",
      introPlaying: "Einführung wird abgespielt...",
      activateAssistant: "Tippen Sie, um den Sprachassistenten zu aktivieren",
      listening: "Höre zu...",
      tourStep: "Tour-Schritt",
      of: "von",
      commands: {
        next: "weiter",
        back: "zurück",
        repeat: "wiederholen",
        help: "hilfe"
      },
      helpMessage: helpGerman,
      commandNotRecognized: commandGerman,
      tourCompleted: finishGerman,
      cannotGoBack: backGerman,
    }
  },
  it: {
    code: 'it-IT',
    name: 'Italiano',
    intro: introItalian,
    tourSteps: [
      {
        id: 1,
        description: backItalian,
      },
      {
        id: 2,
        description: step2Italian,
      },
      {
        id: 3,
        description: step3Italian,
      },
    ],
    uiText: {
      title: "Benvenuto alla",
      introPlaying: "Riproduzione dell'introduzione...",
      activateAssistant: "Tocca per attivare l'assistente vocale",
      listening: "In ascolto...",
      tourStep: "Passo del tour",
      of: "di",
      commands: {
        next: "successivo",
        back: "indietro",
        repeat: "ripeti",
        help: "aiuto"
      },
      helpMessage: helpItalian,
      commandNotRecognized: commandItalian,
      tourCompleted: finishItalian,
      cannotGoBack: backItalian,
    }
  },
  es: {
    code: 'es-ES',
    name: 'Español',
    intro: introSpanish,
    tourSteps: [
      {
        id: 1,
        description: backSpanish,
      },
      {
        id: 2,
        description: step2Spanish,
      },
      {
        id: 3,
        description: step3Spanish,
      },
    ],
    uiText: {
      title: "Bienvenido a la",
      introPlaying: "Reproduciendo introducción...",
      activateAssistant: "Toca para activar el asistente de voz",
      listening: "Escuchando...",
      tourStep: "Paso del recorrido",
      of: "de",
      commands: {
        next: "siguiente",
        back: "atrás",
        repeat: "repetir",
        help: "ayuda"
      },
      helpMessage: helpSpanish,
      commandNotRecognized: commandSpanish,
      tourCompleted: finishSpanish,
      cannotGoBack: backSpanish,
    }
  }
};

const MultilingualVoiceAssistant = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialLang = queryParams.get('lang') || 'en';

  const [currentLanguage, setCurrentLanguage] = useState(initialLang);
  const [currentStep, setCurrentStep] = useState(0);
  const [audioError, setAudioError] = useState(null);
  const [introductionPlayed, setIntroductionPlayed] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [assistantReady, setAssistantReady] = useState(false);
  const [nextAudioReady, setNextAudioReady] = useState(false);
  const [pendingAudio, setPendingAudio] = useState(null);
  const audioRef = useRef(null);
  const recognitionRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);

  const handleAssistantActivation = () => {
    if (introductionPlayed) {
      if (!isActive) {
        handleSpeechRecognition();
        setIsActive(true);
      } else {
        stopSpeechRecognition();
        setIsActive(false);
        if (pendingAudio) {
          setCurrentAudio(pendingAudio);
          setIsAudioReady(true);
          setPendingAudio(null);
        }
      }
    }
  };

  useEffect(() => {
    if (!languages[currentLanguage]) {
      setCurrentLanguage('en');
      return;
    }

    // Start playing the introduction audio immediately
    playIntroduction();

    return () => {
      stopAudio();
      stopSpeechRecognition();
    };
  }, [currentLanguage]);

  const playIntroduction = () => {
    audioRef.current = new Audio(languages[currentLanguage].intro);
    audioRef.current.addEventListener('ended', handleIntroductionEnded);
    audioRef.current.play().catch(error => {
      console.error("Error playing introduction audio:", error);
      setAudioError("Failed to play introduction audio. Please check your audio settings.");
    });
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.removeEventListener('ended', handleIntroductionEnded);
    }
  };

  const stopSpeechRecognition = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  const handleIntroductionEnded = () => {
    setIntroductionPlayed(true);
    setAssistantReady(true);
  };

  const handleSpeechRecognition = () => {
    if (assistantReady) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (SpeechRecognition) {
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.lang = languages[currentLanguage].code;
        recognitionRef.current.continuous = false;
        recognitionRef.current.interimResults = false;

        // Enable noise cancellation and improve voice recognition accuracy
        recognitionRef.current.speechRecognitionOptions = {
          interimResults: false,
          continuous: false,
          sampleRate: 16000,
          maxAlternatives: 1,
          soundLevel: 0.5,
          vadThreshold: 0.5,
        };

        recognitionRef.current.onstart = () => {
          console.log('Speech recognition started');
          setIsListening(true);
        };

        recognitionRef.current.onend = () => {
          console.log('Speech recognition ended');
          setIsListening(false);
        };

        recognitionRef.current.onerror = (event) => {
          console.error('Speech recognition error:', event.error);
          setIsListening(false);
        };

        recognitionRef.current.onresult = async (event) => {
          const command = event.results[0][0].transcript.toLowerCase();
          console.log('Recognized command:', command);
          await handleCommand(command);

          // Stop speech recognition after a command is recognized
          recognitionRef.current.stop();
        };

        recognitionRef.current.start();
      } else {
        setAudioError("Speech recognition is not supported on this device.");
      }
    }
  };

  const handleCommand = async (command) => {
    stopSpeechRecognition();
    const { commands } = languages[currentLanguage].uiText;
    let audioToPlay;
    let newStep = currentStep;

    if (command.includes(commands.next)) {
      if (currentStep < languages[currentLanguage].tourSteps.length - 1) {
        newStep = currentStep + 1;
        audioToPlay = languages[currentLanguage].tourSteps[newStep].description;
      } else {
        audioToPlay = languages[currentLanguage].uiText.tourCompleted;
      }
    } else if (command.includes(commands.back)) {
      if (currentStep > 0) {
        newStep = currentStep - 1;
        audioToPlay = languages[currentLanguage].tourSteps[newStep].description;
      } else {
        audioToPlay = languages[currentLanguage].uiText.cannotGoBack;
      }
    } else if (command.includes(commands.repeat)) {
      audioToPlay = languages[currentLanguage].tourSteps[currentStep].description;
    } else if (command.includes(commands.help)) {
      audioToPlay = languages[currentLanguage].uiText.helpMessage;
    } else {
      audioToPlay = languages[currentLanguage].uiText.commandNotRecognized;
    }

    setCurrentStep(newStep);
    setCurrentAudio(audioToPlay);
    setIsAudioReady(true);
    setAssistantReady(false);
  };

  const playAudio = async () => {
    if (currentAudio) {
      setIsAudioReady(false);
      await new Promise((resolve, reject) => {
        stopAudio();
        audioRef.current = new Audio(currentAudio);

        audioRef.current.oncanplaythrough = () => {
          audioRef.current.play()
            .then(() => {
              console.log("Audio started playing successfully");
            })
            .catch(error => {
              console.error("Error playing audio:", error);
              reject(error);
            });
        };

        audioRef.current.onended = () => {
          console.log("Audio finished playing");
          setAssistantReady(true);
          resolve();
        };

        audioRef.current.onerror = (event) => {
          console.error("Error loading audio:", event);
          reject(new Error("Failed to load audio"));
        };

        audioRef.current.load();
      });
    }
  };

  const prepareNextStep = () => {
    if (currentStep < languages[currentLanguage].tourSteps.length - 1) {
      const nextStepIndex = currentStep + 1;
      return languages[currentLanguage].tourSteps[nextStepIndex].description;
    } else {
      return languages[currentLanguage].uiText.tourCompleted;
    }
  };

  const preparePreviousStep = () => {
    if (currentStep > 0) {
      const prevStepIndex = currentStep - 1;
      return languages[currentLanguage].tourSteps[prevStepIndex].description;
    } else {
      return languages[currentLanguage].uiText.cannotGoBack;
    }
  };

  const prepareRepeatStep = () => {
    return languages[currentLanguage].tourSteps[currentStep].description;
  };

  const handleBackClick = () => {
    stopAudio();
    stopSpeechRecognition();
    navigate(-1);
  };

  const handleNextAudioClick = async () => {
    if (nextAudioReady && pendingAudio) {
      await playAudio(pendingAudio);
      setPendingAudio(null);
      setNextAudioReady(false);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="sticky top-0 z-10 bg-white">
        <div className="flex items-center justify-between px-4 py-4">
          <button 
            onClick={handleBackClick} 
            className="text-black hover:text-blue-800"
            aria-label={languages[currentLanguage].uiText.backButton}
          >
            <ArrowLeft size={24} />
          </button>
          <div className="text-center">
            <h1 className="text-xl">{languages[currentLanguage].uiText.title}</h1>
            <h2 className="text-2xl font-bold">GALLERIA BORGHESE</h2>
          </div>
          <div className="w-6" />
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center justify-center p-4">
        {!introductionPlayed ? (
          <p className="text-xl font-bold">{languages[currentLanguage].uiText.introPlaying}</p>
        ) : (
          <>
            {isAudioReady ? (
              <button
                onClick={playAudio}
                className="w-64 h-64 rounded-full bg-green-500 text-white text-2xl font-bold shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors duration-200"
                aria-label="Play Audio"
              >
                Play
              </button>
            ) : (
              <button
                onClick={handleAssistantActivation}
                className={`w-64 h-64 rounded-full text-white text-2xl font-bold shadow-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 ${
                  isActive ? 'bg-red-500' : 'bg-black'
                }`}
                aria-label={isActive ? languages[currentLanguage].uiText.listening : languages[currentLanguage].uiText.activateAssistant}
              >
                {isActive ? languages[currentLanguage].uiText.listening : languages[currentLanguage].uiText.activateAssistant}
              </button>
            )}
            <p className="text-lg mt-8">
              {languages[currentLanguage].uiText.tourStep}: {currentStep + 1} {languages[currentLanguage].uiText.of} {languages[currentLanguage].tourSteps.length}
            </p>
          </>
        )}
      </div>
    </div>
  );  
};

export default MultilingualVoiceAssistant;